import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="mb-12 text-white bg-helpappy-red  ">
      <div className="container flex flex-col items-center text-center px-8 py-16 mx-auto">
        <StaticImage
          src="../images/logo.png"
          alt="helpappy logo"
          layout="fixed"
          placeholder="blurred"
          width={200}
          quality={100}
          className="mb-12"
        />
        <p className="text-2xl font-bold mb-4 md:mb-0">
          Helpappy, your very own life admin PA.
        </p>
        <p className="text-2xl font-bold mb-12">
          You’ll have less to do but more will get done.
        </p>
        <p>
          <a className="text-xl" href="mailto:support@helpappy.com">
            support@helpappy.com
          </a>
        </p>
      </div>
    </div>
    <div className="container mx-auto px-8 mb-16 md:mb-28 md:max-w-6xl relative text-center	">
      <StaticImage
        src="../images/tara_ceo.png"
        alt="Tara ceo"
        layout="constrained"
        placeholder="blurred"
        className="-mb-8 w-32 sm:w-48"
      />
      <p className="font-kalam text-xl sm:text-3xl	bg-yellow-50 p-8 sm:p-12 rounded-xl	">
        Hi y'all, I am Tara Grossman, the co-founder of Helpappy. I started
        Helpappy when I had a busy corporate job in law and an even busier
        personal life and just couldn't cope with doing all the personal life
        admin anymore… So I made the decision to outsource it and I haven't
        looked back!
      </p>
    </div>
    <div className="container mx-auto px-8 mb-16 md:mb-28 md:max-w-6xl">
      <h2 className="text-center mb-4 md:mb-8 text-4xl font-bold">
        We can do it for you
      </h2>
      <div className="grid gap-2 md:gap-4 grid-cols-12">
        <div className="mb-2 relative col-span-6 md:col-span-4  text-center">
          <StaticImage
            src="../images/Multitasking.png"
            alt="everyday admin"
            layout="constrained"
            placeholder="blurred"
          />
          <h3 className="text-base md:text-2xl mb-0">Everyday Admin</h3>
        </div>
        <div className="mb-2 relative col-span-6 md:col-span-4  text-center">
          <StaticImage
            src="../images/CarMaintenance.png"
            alt="everyday admin"
            layout="constrained"
            placeholder="blurred"
          />
          <h3 className="text-base md:text-2xl mb-0">Tradespeople</h3>
        </div>
        <div className="mb-2 relative col-span-6 md:col-span-4   text-center">
          <StaticImage
            src="../images/FashionShow.png"
            alt="event booking and tickets"
            layout="constrained"
            placeholder="blurred"
          />
          <h3 className="text-base md:text-2xl mb-0">
            Event Booking &#38; Tickets
          </h3>
        </div>
        <div className="mb-2 relative col-span-6 md:col-span-4   text-center">
          <StaticImage
            src="../images/Endofseasonsale.png"
            alt="gift finding"
            layout="constrained"
            placeholder="blurred"
          />
          <h3 className="text-base md:text-2xl mb-0">Gift Finding</h3>
        </div>
        <div className="mb-2 relative col-span-6 md:col-span-4   text-center">
          <StaticImage
            src="../images/Coupleholiday.png"
            alt="holiday and travel booking"
            layout="constrained"
            placeholder="blurred"
          />
          <h3 className="text-base md:text-2xl mb-0">Holiday &#38; Travel</h3>
        </div>
        <div className="mb-2 relative col-span-6 md:col-span-4   text-center">
          <StaticImage
            src="../images/FineDining.png"
            alt="restaurant reservations"
            layout="constrained"
            placeholder="blurred"
          />
          <h3 className="text-base md:text-2xl mb-0">
            Restaurant Reservations
          </h3>
        </div>
      </div>
    </div>
    <div className="bg-helpappy-light-indigo py-8 mb-16 md:mb-28 pt-16">
      <div className="container mx-auto px-8 ">
        <h2 className="text-center mb-4 md:mb-8 text-4xl font-bold ">
          How it works
        </h2>

        <div className="md:flex-row-reverse md:flex	md:max-w-2xl mx-auto mb-10 md:mb-14">
          <div className="w-6/12 md:w-4/12 mx-auto relative mb-4 md:ml-4">
            <StaticImage
              src="../images/Letterbetweenfriends.png"
              alt="contact us"
              layout="constrained"
              placeholder="blurred"
            />
            <div className="absolute text-8xl md:text-9xl text-helpappy-red -bottom-2.5 right-0">
              1
            </div>
          </div>
          <div className="md:w-8/12">
            <h3 className="text-xl md:text-2xl font-bold mb-4">Contact Us</h3>
            <p className="text-xl">
              Contact us{" "}
              <a
                className="text-xl text-helpappy-red"
                href="mailto:support@helpappy.com"
              >
                here
              </a>{" "}
              or email us at{" "}
              <a
                className="text-xl text-helpappy-red"
                href="mailto:support@helpappy.com"
              >
                support@helpappy.com
              </a>{" "}
              and we will be in touch ASAP to discuss your needs.
            </p>
          </div>
        </div>
        <div className="md:flex-row md:flex	md:max-w-2xl mx-auto mb-10 md:mb-14">
          <div className="w-6/12 md:w-4/12 mx-auto relative mb-4 md:mr-4">
            <StaticImage
              src="../images/Videocollaboration.png"
              alt="personal admin"
              layout="constrained"
              placeholder="blurred"
            />
            <div className="absolute text-8xl md:text-9xl text-helpappy-red -bottom-2.5 right-0">
              2
            </div>
          </div>
          <div className="md:w-8/12">
            <h3 className="text-xl md:text-2xl font-bold mb-4">
              We will pair you with your very own Life Admin PA
            </h3>
            <p className="text-xl">
              You’ll get your very own PA who can help you run your life
              including finding nannies, tradespeople, sorting insurance,
              organising birthday parties and so much more.
            </p>
          </div>
        </div>
        <div className="md:flex-row-reverse md:flex	md:max-w-2xl mx-auto mb-10 md:mb-14">
          <div className="w-6/12 md:w-4/12 mx-auto relative mb-4">
            <StaticImage
              src="../images/Virtualofficegirl.png"
              alt="virtual assistant"
              layout="constrained"
              placeholder="blurred"
            />
            <div className="absolute text-8xl md:text-9xl text-helpappy-red -bottom-2.5 right-0">
              3
            </div>
          </div>
          <div className="md:w-8/12">
            <h3 className="text-xl md:text-2xl  font-bold mb-4">
              You’ll get access to our highly specialised PAs
            </h3>
            <p className="text-xl">
              You can use our highly specialised PAs with decades of experience
              between them and the best connections in the industry to book
              restaurants, travel and theatre/concert tickets.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="container mx-auto px-8">
      <h2 className="text-center mb-4  text-4xl font-bold">Pricing</h2>
      <p className="text-center mb-8 md:mb-12 text-2xl">
        Pay as you go or buy one of our hour bundles for an extra discount.
      </p>
      <div className="md:grid  md:gap-4 md:grid-cols-10 md:grid-rows-4 md:max-w-4xl md:mx-auto mt-10 mb-16 md:mb-0">
        <div className="mb-6 flex-col items-center md:row-span-2 md:col-span-3  md:row-start-2 text-white bg-helpappy-indigo flex-1 shadow-lg  p-4 md:p-8 rounded-xl flex justify-center	">
          <p className="text-xl	mb-2 md:md-12">Pay as you go</p>
          <p className="text-4xl md:text-6xl font-bold mb-2 md:md-12">£30</p>
          <p className="text-xl	mb-0">Per Hour</p>
        </div>
        <div className="mb-8 relative md:col-span-4  md:row-start-1 md:row-end-4 pt-8">
          <div className="absolute z-0 top-0 h-14 p-2 bg-helpappy-red text-white rounded-xl font-bold">
            Most Popular
          </div>
          <div className="z-10  relative flex-col md:h-full	 items-center text-white bg-helpappy-indigo flex-1 shadow-lg p-4 md:p-8  rounded-xl  flex justify-center	">
            <p className="text-xl md:text-3xl mb-2 md:md-12	">5 Hours</p>
            <p className="text-4xl md:text-8xl font-bold  mb-2 md:md-12">£25</p>
            <p className="text-xl md:text-3xl mb-0 ">Per Hour</p>
          </div>
        </div>
        <div className="mb-8 flex-col items-center md:col-span-3  md:row-start-2 md:row-span-2 text-white bg-helpappy-indigo flex-1 shadow-lg  p-4 md:p-8 rounded-xl  flex justify-center	">
          <p className="text-xl md:text-3xl	 mb-2 md:md-12">10 Hours</p>
          <p className="text-4xl md:text-6xl font-bold  mb-2 md:md-12">£20</p>
          <p className="text-xl md:text-3xl	 mb-0 ">Per Hour</p>
        </div>
      </div>
    </div>
    <div className="container mx-auto px-8 text-center">
      <h2 className="text-center mb-4  text-3xl md:text-4xl font-bold">
        Interested? Get started by contacting us
      </h2>
      <p>
        <a
          className="text-2xl text-helpappy-red font-bold"
          href="mailto:support@helpappy.com"
        >
          support@helpappy.com
        </a>
      </p>
    </div>
  </Layout>
)

export default IndexPage
